<template>
    <div
        v-if="options && options.length"
        class="filter-select"
    >
        <multiselect
            :id="id"
            :value="activeOption"
            :model-value="activeOption"
            :name="id"
            :track-by="trackByProperty"
            label="title"
            :placeholder="activeOption?.title ? activeOption.title : placeholder"
            :options="options"
            :searchable="searchable"
            :close-on-select="true"
            :show-labels="false"
            :allow-empty="true"
            :preserve-search="true"
            @update:model-value="updateValue($event)"
        />
    </div>
</template>

<script setup lang="ts">
import Multiselect from 'vue-multiselect';

const props = defineProps({
    modelValue: {
        type: [String, Object],
        required: false,
        default: ''
    },
    options: {
        type: Array,
        required: true,
        default: () => []
    },
    placeholder: {
        type: String,
        required: false,
        default: 'Select an option'
    },
    id: {
        type: String,
        required: false,
        default: ''
    },
    addEmptyOption: {
        type: Boolean,
        required: false,
        default: true
    },
    trackByProperty: {
        type: String,
        default: 'slug'
    },
    searchable: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue']);

const activeOption = computed(() => {
    if (!props.modelValue) {
        return null;
    }

    return props.options?.find(item =>
        item?.[props.trackByProperty] === props.modelValue?.[props.trackByProperty]
    );
});

const updateValue = (selected) => {
    emit('update:modelValue', selected?.[props?.trackByProperty] ?
        selected[props.trackByProperty] :
        undefined
    );
};
</script>
<style lang="less" src="./BaseFilterSelect.less" />
